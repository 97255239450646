import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'dat.gui'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap} from "gsap";
//import { ScrollTrigger } from "gsap/ScrollTrigger.js";


/**
 * Base
 */
// Debug
const gui = new dat.GUI()


/**
 * Sizes
 */
const sizes = {
    width: 180,
    height: 180
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = 180
    sizes.height = 180

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update effect composer
    effectComposer.setSize(sizes.width, sizes.height)

})




// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)


/**
 * Environment map
 */

scene.background = new THREE.Color( "#DEDAD7" );



/**
 * Models
 */
let camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.0001, 2000)
let actions = [];
let mixer = null
gltfLoader.load('untitled.glb', (gltf) =>{

    gltf.scene.scale.set(1, 1, 1)
    scene.add(gltf.scene)

    //console.log(gltf)
    // Animation
    mixer = new THREE.AnimationMixer(gltf.scene)
    
    for(let anim of gltf.animations ){
        const action = mixer.clipAction(anim)
        actions.push(action)
    }
    //actions[0].setDuration( 20 ).play()


    gltf.scene.traverse((object) => {
      if (object.isCamera){
        //console.log(object)
        camera = object;
        renderPassAdd(camera)
      }
    });

    //console.log(scene)

    //console.log("MIXER", mixer)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()



    //GSAP
    onmousemove = function(e){
        let xShift = 1.58 + (e.clientX - window.innerWidth/2)/1000
        let yShift = 0.2 + (e.clientY - window.innerWidth/2)/1000
        //console.log(scene.children[2].children[2].rotation)

        if(xShift>=2){xShift = 2}
        if(xShift<=1){xShift = 1}

        if(yShift>=0.3){yShift = 0.3}
        if(yShift<=-0.3){yShift = -0.3}

        gsap.to(scene.children[2].children[2].rotation, {
            x: xShift,
            y: yShift,
            duration: 1,
            ease: "none"
        });
    }
    console.log( Math.PI)
    gsap.to(scene.children[2].children[2].rotation, {
        z: -Math.PI,
        repeat: -1,
        duration: 30,
        ease: "none"
    });
})




let oldValue = 0;

window.addEventListener('scroll' , function(e){
    //console.log(window.pageYOffset)
    var newValue = window.pageYOffset;

    const anim_scroll = ()=>{
        if(oldValue - newValue < 0){
            mixer.timeScale !== 1 ? mixer.timeScale = 1 : mixer.update((newValue-oldValue)/2000) 
        } 
        else if(oldValue - newValue > 0){
            mixer.timeScale !== -1 ? mixer.timeScale = -1 : mixer.update((oldValue-newValue)/2000)  
        }
        oldValue = newValue; 
    }

    setTimeout(anim_scroll, 20)

   
});






/**
 * Floor
 */

let floorParams = {
    color: "#960018",
    toneMapped: false
};

const floor = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(1000, 1000),
    new THREE.MeshStandardMaterial(floorParams)
)
floor.name = "floor"
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
floor.position.y = 0
//scene.add(floor)



/**
 * Lights
 */


const ambientLight = new THREE.AmbientLight("#cc5801", 0)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight("#807873", 20)
directionalLight.castShadow = true
directionalLight.position.set(10, 10, 30)

scene.add(directionalLight)


gui.hide()


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


renderer.setClearColor( 0x000000, 0 )


/**
 * Post processing
 */
let RenderTargetClass = null

if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2){
    RenderTargetClass = THREE.WebGLMultisampleRenderTarget
    //console.log('Using WebGLMultisampleRenderTarget')
}else{
    RenderTargetClass = THREE.WebGLRenderTarget
    //console.log('Using WebGLRenderTarget')
}

const renderTarget = new RenderTargetClass(
    800,
    600,
    {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        encoding: THREE.sRGBEncoding
    }
)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)

const renderPassAdd = (camera)=>{
    // Render pass
    const renderPass = new RenderPass(scene, camera)
    effectComposer.addPass(renderPass)
}


// Antialias pass
if(renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2){
    const smaaPass = new SMAAPass()
    effectComposer.addPass(smaaPass)
    //console.log('Using SMAA')
}






/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0


const tick = () =>{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    if(mixer){
        mixer.update(deltaTime)
    }

    effectComposer.render()

    window.requestAnimationFrame(tick)

}

tick()